/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState} from 'react';
import ViewVendor from '../../pages/dashboard/admin/modal/ViewVendor';

interface IVendorTable {
  commissions: any[];
}
const CommissionTable = ({commissions}: IVendorTable) => {
  const [showView, setShowView] = useState(false);

  return (
    <>
      {/* Modal View */}
      {showView && (
        <div className='z-50'>
          <ViewVendor setIsOpen={setShowView} />
        </div>
      )}
      <div>
        <table className='mt-6 w-full overflow-scroll text-xs border-t border-transparent rounded-tr-lg rounded-tl-lg bg-primary'>
          <thead>
            <tr className='h-[3.125rem] text-white'>
              <th className='w-16 rounded-tl-md'>Date</th>
              <th className='w-16'>Product ID</th>
              <th className='w-16'>Product Name</th>
              <th className='w-16'>Vendor Name</th>
              <th className='w-16'>Category</th>
              <th className='w-16'>Price</th>
              <th className='w-16 rounded-tr-md '>Commission Fee</th>
            </tr>
          </thead>
          <tbody className='[&>*:nth-child(odd)]:bg-white [&>*:nth-child(even)]:bg-orange-100'>
            {commissions.length === 0 && (
              <tr>
                <td className='text-center py-6' colSpan={7}>
                  <span className='text-xl text-primary font-semibold'>
                    No Vendor available
                  </span>
                </td>
              </tr>
            )}
            <>
              {commissions.map((commission) => {
                return (
                  <tr className='overflow-x-auto' key={commission.id}>
                    <td className='h-20'>{commission.createdAt}</td>
                    <td className='w-10 text-center'>{commission.id}</td>
                    <td className='w-10 text-center'>{commission.name}</td>
                    <td className='w-10 text-center'>
                      {commission.vendorName}
                    </td>
                    <td className='w-10 text-center'>{commission.category}</td>
                    <td className='w-10 text-center'>{commission.price}</td>
                    <td className='w-10 text-center'>{commission.fee}</td>
                    {/* Status */}
                  </tr>
                );
              })}
            </>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CommissionTable;
