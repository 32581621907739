import {COLORS} from '../../assets/color';

interface IButton {
  width: 'full' | 'medium' | 'small';
  label: any;
  onClick?: any;
  disabled?: boolean;
  bgColor?: string;
  textCOlor?: string;
  borderCOlor?: string;
  icon?: boolean;
}
const Button = ({
  width,
  label,
  disabled,
  onClick,
  bgColor,
  borderCOlor,
  textCOlor,
  icon,
}: IButton) => {
  return (
    <>
      {width === 'full' ? (
        <div
          className='login_button w-full py-3 my-2 rounded-md border-[1.5px] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-100 duration-300 '
          style={{}}>
          <button
            style={{
              color: textCOlor ? textCOlor : COLORS.white,
              backgroundColor: bgColor ? bgColor : COLORS.primary,
              borderColor: borderCOlor ? borderCOlor : COLORS.primary,
            }}
            type='button'
            className='w-full h-full font-poppinsMedium flex flex-row justify-center items-center gap-2'
            onClick={onClick}
            disabled={disabled}>
            <span className='text-sm font-poppinsMedium'>{label}</span>
          </button>
        </div>
      ) : width === 'medium' ? (
        <div
          className='login_button h-14 mt-4 mb-4 w-52 rounded-md transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-100 duration-300 border-2'
          // style={disabled ? {opacity: 0.3} : {opacity: 1}}
          style={{
            backgroundColor: bgColor ? bgColor : COLORS.primary,
            borderColor: borderCOlor ? borderCOlor : COLORS.primary,
          }}>
          <button
            style={{color: textCOlor ? textCOlor : COLORS.white}}
            type='button'
            className=' text-lg w-full h-full rounded-lg font-poppinsMedium'
            onClick={onClick}
            disabled={disabled}>
            <span className='text-lg font-circularStdBook'>{label}</span>
          </button>
        </div>
      ) : (
        width === 'small' && (
          <div
            className='h-10 w-36 rounded-md transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-100 duration-300'
            style={{
              backgroundColor: bgColor ? bgColor : COLORS.primary,
              borderColor: borderCOlor ? borderCOlor : COLORS.primary,
            }}>
            <button
              style={{color: textCOlor ? textCOlor : COLORS.white}}
              type='button'
              className='w-full h-full rounded-lg font-poppinsMedium'
              onClick={onClick}>
              <span className='font-poppinsMedium'>{label}</span>
            </button>
          </div>
        )
      )}
    </>
  );
};

export default Button;
